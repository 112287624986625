.users-comp {
  .user-cell-comp {
    display: flex;
    align-items: center;
    cursor: pointer;

    .info-container { flex: 1; }

    i {
      font-size: var(--action-icon-size);

      &.external-auth-icon {
        font-size: 20px;
        color: var(--color-neutral-dark);
        margin-left: var(--pad-xs);
      }
    }
  }

  .store-select-comp {
    background: inherit;
    border: none;
    outline: none;
  }
}

.users-filter-comp {
  gap: var(--pad-sm);
}
