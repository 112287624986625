.log-window-comp {
  max-height: 30vh;
  font-size: 10pt;
  overflow: scroll;
  padding: var(--pad-xs);

  .logs-container {
    gap: var(--pad-xs);

    .export-actions-container {
      gap: var(--pad-lg);
      position: fixed;
      right: 4pt;
      color: var(--color-primary-dark);

      .clear-btn {
        color: var(--mti-red);
      }
    }
  }
}
